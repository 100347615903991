import React from 'react'

/**
 * @param {Record<string, any>} props
 */
export default ({ children, id, ...props }) => (
  <section {...props} css={{ position: 'relative', zIndex: 0 }}>
    {/* biome-ignore lint/a11y/useAnchorContent: marketing site cares less */}
    {id && <a name={id} />}
    {children}
  </section>
)
