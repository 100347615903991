import { colors, getColor } from '@blissbook/ui/branding'
import { Link } from '@blissbook/ui/lib'
import range from 'lodash/range'
import React, { Fragment, useEffect, useState } from 'react'

const navItems = [
  {
    color: 'sunshine-700',
    id: 'employee-handbook-builder',
    label: 'Create.',
  },
  {
    color: 'red-500',
    id: 'online-employee-handbook',
    label: 'Launch.',
  },
  {
    color: 'aqua-500',
    id: 'policy-management',
    label: 'Update.',
  },
  {
    color: 'blurple-500',
    id: 'policy-compliance-tracking',
    label: 'Track.',
  },
  {
    color: 'purple-300',
    id: 'employee-experience',
    label: 'Experience.',
  },
].map((item) => ({
  ...item,
  color: getColor(item.color),
}))

// Get the current navItem
const getCurrentNavItem = () =>
  navItems.find((navItem) => {
    const anchorNode = document.querySelector(`a[name=${navItem.id}]`)
    const anchorRect = anchorNode.getBoundingClientRect()
    const sectionRect = anchorNode.parentNode.getBoundingClientRect()
    const { top } = anchorRect
    const bottom = top + sectionRect.height
    return top <= 0 && bottom > 0
  })

// Hook to get the current section
export const useCurrentNavItem = () => {
  const [currentNavItem, setCurrentNavItem] = useState()

  useEffect(() => {
    // Handle changes
    const onChange = () => {
      const navItem = getCurrentNavItem()
      setCurrentNavItem(navItem)
    }
    onChange()

    // Add / Remove handlers
    window.addEventListener('resize', onChange, false)
    window.addEventListener('scroll', onChange, false)
    return () => {
      window.removeEventListener('resize', onChange, false)
      window.removeEventListener('scroll', onChange, false)
    }
  }, [])

  return currentNavItem
}

export default () => {
  const currentNavItem = useCurrentNavItem()
  return (
    <div
      className='tw-hidden lg:tw-flex tw-flex-col tw-items-end tw-justify-center'
      css={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 15,
        zIndex: 100,
        pointerEvents: 'none',
      }}
    >
      {navItems.map((navItem, index) => (
        <Fragment key={index}>
          {index > 0 &&
            range(0, 2).map((index) => (
              <div
                key={index}
                css={{
                  border: `2px solid ${colors['blurple-500']}`,
                  borderRadius: '50%',
                  margin: '6px 5px',
                }}
              />
            ))}

          <Link
            className='tw-flex tw-items-center'
            css={{
              color: navItem.color,
              fontSize: 14,
              fontWeight: 700,
              letterSpacing: 2,
              margin: '-1px 0',
              textTransform: 'uppercase',
              '&:hover': {
                color: navItem.color,
                textDecoration: 'none',
                span: {
                  opacity: 1,
                  pointerEvents: 'all',
                },
              },
            }}
            to={`#${navItem.id}`}
          >
            <span
              children={navItem.label}
              className='tw-mr-2'
              css={{
                background: 'white',
                borderRadius: 2,
                opacity: 0,
                pointerEvents: 'none',
                padding: '0px 4px',
                transition: 'all 200ms linear',
              }}
            />

            <div
              css={{
                background: navItem.color,
                border: '2px solid white',
                borderRadius: '50%',
                opacity: navItem === currentNavItem ? 1 : 0.2,
                padding: 5,
                pointerEvents: 'all',
              }}
            />
          </Link>
        </Fragment>
      ))}
    </div>
  )
}
