import { colors } from '@blissbook/ui/branding'
import { css } from '@emotion/react'
import classnames from 'classnames'
import React, { Fragment } from 'react'
import { MarketingLayout } from './MarketingLayout'
import Typography from './Typography'

const maxWidth = 960

export const LegalLayout = ({ children, ...props }) => (
  <MarketingLayout
    {...props}
    className='container'
    css={css`
      h3 {
        color: ${colors.black};
        font-size: 24px;
        font-weight: 600;
        letter-spacing: normal;
        line-height: 34px;
        margin-top: 32px;
        margin-bottom: 16px;
        text-transform: none;
      }

      p,
      ol,
      ul {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 36px;
      }
    `}
  >
    <div className='tw-mx-auto' css={{ maxWidth }}>
      {children}
    </div>
  </MarketingLayout>
)

export const LegalHeader = ({ heading, lastUpdated, title }) => (
  <Fragment>
    <Typography variant='h4' className='tw-mx-auto text-center mt-sm'>
      {heading}
    </Typography>

    <Typography variant='h1' className='tw-mx-auto text-center'>
      {title}
    </Typography>

    <Typography variant='p2' className='tw-mx-auto text-center mb-sm'>
      Last Updated: {lastUpdated}
    </Typography>
  </Fragment>
)

/** *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export const LegalIndent = ({ className, ...props }) => (
  <div {...props} className={classnames(className, 'pl-10')} />
)
