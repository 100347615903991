import activeDirectoryIcon from '@blissbook/assets/images/integrations/active-directory.svg'
import oktaIcon from '@blissbook/assets/images/integrations/okta-white.png'
import office365Icon from '@blissbook/assets/images/office-365-signIn.png'
import { colors } from '@blissbook/ui/branding'
import { BackgroundImage, BackgroundShapeGrid } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { Typography } from '../../../components'
import { DemoDesktopBrowser, demoColors } from './components'

const iconSize = 24
const ssoTransitionDelay = 1250
const ssoTransitionDuration = 1000

const SSOS = [
  {
    backgroundColor: 'rgb(250, 94, 97)',
    color: 'white',
    icon: (
      <FontAwesomeIcon icon={['fab', 'google']} css={{ fontSize: iconSize }} />
    ),
    label: 'Google Suite',
  },
  {
    backgroundColor: 'rgb(212, 62, 64)',
    color: 'white',
    icon: (
      <BackgroundImage src={office365Icon} height={iconSize} width={iconSize} />
    ),
    label: 'Azure / Office 365',
  },
  {
    backgroundColor: 'rgb(27, 143, 186)',
    color: 'white',
    icon: (
      <BackgroundImage
        src={oktaIcon}
        height={iconSize}
        width='100%'
        size='contain'
      />
    ),
  },
  {
    backgroundColor: 'rgb(50, 171, 224)',
    color: 'white',
    icon: (
      <BackgroundImage
        src={activeDirectoryIcon}
        height={iconSize}
        width={iconSize}
      />
    ),
    label: 'Active Directory',
  },
  {
    backgroundColor: colors['blurple-700'],
    color: 'white',
    icon: <FontAwesomeIcon icon={['far', 'envelope']} />,
    label: 'Email Address',
  },
]

const Component = ({ isAnimationComplete, keyframe }) => (
  <DemoDesktopBrowser css={{ zIndex: 0 }} disabled={isAnimationComplete}>
    <BackgroundShapeGrid
      alignX='right'
      alignY='bottom'
      shapeDefaults={{ fill: true }}
      shapeSize={50}
      shapes={[
        { x: 0, y: 0, type: 'wedge', color: demoColors.grayLight, rotate: 180 },
        { x: 1, y: 0, type: 'square', color: demoColors.grayLighter },
        { x: 2, y: 0, type: 'square', color: demoColors.grayLight },
        { x: 3, y: 0, type: 'wedge', color: demoColors.grayWhite },

        { x: 0, y: 1, type: 'quotes', color: demoColors.gray, scale: 0.6 },
        { x: 1, y: 1, type: 'square', color: demoColors.gray },
        {
          x: 2,
          y: 1,
          type: 'wedge',
          color: demoColors.grayLighter,
          rotate: 180,
        },

        { x: 0, y: 2, type: 'square', color: demoColors.grayLighter },
        { x: 1, y: 2, type: 'wedge', color: demoColors.grayLight },

        { x: 1, y: 3, type: 'square', color: demoColors.grayWhite },

        { x: 2, y: 4, type: 'square', color: demoColors.grayLighter },
      ]}
    />

    <Typography variant='h2' className='tw-px-18 tw-mt-18'>
      Sign in via:
    </Typography>

    <div
      css={{
        marginLeft: `${-100 * keyframe.ssoIndex}%`,
        overflow: 'hidden',
        transition: `all ${ssoTransitionDuration}ms ease`,
        width: `${100 * SSOS.length}%`,
        whiteSpace: 'nowrap',
      }}
    >
      {SSOS.map((sso, index) => (
        <div
          key={index}
          className='tw-px-10'
          css={{
            display: 'inline-block',
            opacity: index === keyframe.ssoIndex ? 1 : 0,
            transition: `all ${ssoTransitionDuration}ms ease`,
            width: `${100 / SSOS.length}%`,
          }}
        >
          <button
            type='button'
            className='btn btn-block tw-flex tw-items-center tw-justify-center'
            css={{
              backgroundColor: keyframe.ssoDisabled
                ? demoColors.gray
                : sso.backgroundColor,
              color: sso.color,
              fontSize: 18,
              fontWeight: 700,
              '&:hover': {
                color: sso.color,
              },
            }}
          >
            {sso.icon}
            {sso.label && <span className='tw-ml-2'>{sso.label}</span>}
          </button>
        </div>
      ))}
    </div>
  </DemoDesktopBrowser>
)

export default {
  id: 'access-control',
  title: 'Access Control',
  description: (
    <Fragment>
      Single Sign-On (SSO) or Blissbook’s no-password access control combined
      with powerful, automated sharing means your Blissbook content is easily
      accessible, even to a private audience.
      <br />
      <br />
      If you have employees who don't have an email address – no worries!
      Blissbook can handle that too.
    </Fragment>
  ),
  Component,

  keyframes: [
    ...SSOS.map((_sso, ssoIndex) => ({
      duration: ssoTransitionDuration + ssoTransitionDelay + 500,
      ssoIndex,
    })),
    {
      duration: 100,
      ssoDisabled: true,
    },
  ],
}
