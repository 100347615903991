import { Col, Row } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import React from 'react'
import {
  BlogCard,
  NewsletterCard,
  Section,
  TwitterCard,
  Typography,
} from '../../components'

const cardClass = 'tw-mx-auto'

const cardCss = {
  minHeight: 360,
  maxWidth: 290,
  width: '100%',
}

const CardColumn = ({ children, title }) => (
  <Col xs={12} lg='auto' xl={3} className='tw-my-6'>
    <Typography variant='h2' className='tw-mx-auto text-center tw-mb-6'>
      {title}
    </Typography>

    {children}
  </Col>
)

export default () => {
  const { blogPosts, tweets } = useStore()
  const blogPost = blogPosts[0]
  const tweet = tweets[0]
  return (
    <Section id='where-to-find-us' className='container py-sm'>
      <Typography variant='h3' className='tw-mx-auto text-center'>
        Where to find us
      </Typography>

      <Row className='tw-justify-center'>
        {blogPost && (
          <CardColumn title='The Blog.'>
            <BlogCard className={cardClass} css={cardCss} post={blogPost} />
          </CardColumn>
        )}

        <CardColumn title='The Newsletter.'>
          <NewsletterCard className={cardClass} css={cardCss} />
        </CardColumn>

        {tweet && (
          <CardColumn title='The Twitter.'>
            <TwitterCard className={cardClass} css={cardCss} tweet={tweet} />
          </CardColumn>
        )}
      </Row>
    </Section>
  )
}
