import { getColor } from '@blissbook/ui/branding'
import { css } from '@emotion/react'
import React, { Fragment } from 'react'
import Typography from './Typography'
import { DotsHorizontal } from './dots'

const marginY = 15

/** *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children
 * @param {Object} [props.divider]
 * @param {string} props.variant
 */
export const HeadingDivider = ({ divider, ...props }) => (
  <Fragment>
    <Typography {...props} className='tw-mb-0' />
    <DotsHorizontal
      css={{
        marginTop: marginY,
        marginBottom: marginY,
      }}
      dotCount={4}
      {...divider}
    />
  </Fragment>
)

export const LineDivider = ({
  color = 'sunshine-500',
  height = 4,
  marginY = 64,
  ...props
}) => (
  <div
    {...props}
    css={css`
      position: relative;
      height: ${height}px;
      margin-top: ${marginY}px;
      margin-bottom: ${marginY}px;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 20%;
        right: 20%;
        background-color: ${getColor(color)};
        border-radius: ${height / 2}px;
      }
    `}
  />
)
