import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import {
  BackgroundShapeGrid,
  Container,
  Link,
  gridShapeSize,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Section from './Section'

export const ResourceLink = ({ children, icon, ...props }) => (
  <Link
    {...props}
    css={css`
      display: flex;
      align-items: center;
      background: #f2f2f2;
      border-radius: 5px;
      color: ${colors.black};
      max-width: 500px;
      padding: 1rem;
      transition: all linear 200ms;

      &:hover {
        background: #e6e6e6;
        color: ${colors.black};
        text-decoration: none;
      }
    `}
  >
    <FontAwesomeIcon
      icon={icon}
      className='tw-ml-2 tw-mr-6'
      css={{ color: colors['sunshine-500'], fontSize: 24 }}
    />

    <div css={{ flex: 1 }}>{children}</div>

    <FontAwesomeIcon
      icon='chevron-right'
      className='tw-ml-6 tw-mr-2'
      css={{ color: colors['aqua-500'], fontSize: 24 }}
    />
  </Link>
)

const shadowPadding = 15

/**
 * @param {Record<string, any>} props
 */
export const ResourceSection = ({
  children,
  hideBackgroundShape = false,
  imageFooter,
  images,
}) => (
  <Section>
    <Container className='tw-flex py-sm'>
      <div className='tw-hidden lg:tw-block tw-mr-14' css={{ flex: 1 }}>
        <div
          className='tw-flex tw-flex-col'
          css={css`
            position: relative;
            height: ${888 + shadowPadding}px;
            margin-left: ${-shadowPadding}px;
            margin-right: ${-shadowPadding}px;
            margin-top: ${-shadowPadding}px;
            overflow: hidden;
            padding-left: ${shadowPadding}px;
            padding-right: ${shadowPadding}px;
            padding-top: ${shadowPadding}px;

            @media (min-width: ${gridBreakpoints.xl}px) {
              height: ${792 + shadowPadding}px;
            }
          `}
        >
          {images.map((image) => (
            <img
              alt={image}
              key={image}
              src={image}
              className='tw-mb-6'
              css={{
                borderRadius: 5,
                boxShadow: 'rgba(51,51,51,.15) 0 2px 21px 0',
              }}
              width='100%'
            />
          ))}

          <div
            css={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background:
                'linear-gradient(180deg, rgba(255,255,255,0) 75%, rgba(255, 255, 255, 1) 100%);',
            }}
          />
        </div>

        {imageFooter}
      </div>

      <div css={{ flex: 2 }}>{children}</div>
    </Container>
    {!hideBackgroundShape && (
      <div
        className='tw-mt-6'
        css={{ position: 'relative', height: gridShapeSize }}
      >
        <BackgroundShapeGrid
          alignX='left'
          alignY='top'
          css={{ left: `calc(50% - ${(gridShapeSize * 11) / 2}px)` }}
          shapeDefaults={{ fill: true }}
          shapes={[
            { x: 0, y: 0, type: 'wedge', color: 'blurple-300', rotate: 90 },
            { x: 1, y: 0, type: 'square', color: 'blurple-700' },
            { x: 2, y: 0, type: 'wedge', color: 'aqua-500', rotate: 180 },
            { x: 3, y: 0, type: 'wedge', color: 'red-300', rotate: 90 },
            { x: 4, y: 0, type: 'quotes', color: 'sunshine-500', scale: 0.6 },
            { x: 5, y: 0, type: 'square', color: 'sunshine-700' },
            { x: 6, y: 0, type: 'wedge', color: 'sunshine-500', rotate: 180 },
            { x: 7, y: 0, type: 'wedge', color: 'red-500', rotate: -90 },
            { x: 9, y: 0, type: 'wedge', color: 'purple-500', rotate: 90 },
            { x: 10, y: 0, type: 'square', color: 'red-500' },
          ]}
        />
      </div>
    )}
  </Section>
)
