import config from '@blissbook/ui-config'
import { Col, Container, ForwardLink, Row } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import take from 'lodash/take'
import React from 'react'
import { BlogCard, Section, Typography } from '../../components'
const { blogUrl } = config

export default () => {
  const { blogPosts } = useStore()
  return (
    <Section id='blog' className='py-sm'>
      <Container>
        <Typography variant='h4' className='text-center'>
          Breaking News
        </Typography>
        <Typography variant='h2' className='text-center'>
          Highlights from the Blissbook blog.
        </Typography>
        <Row className='tw-justify-center'>
          {take(blogPosts, 4).map((post) => (
            <Col key={post.slug} xs={12} sm='auto' xl={3} className='tw-my-6'>
              <BlogCard
                className='tw-mx-auto'
                css={{
                  fontWeight: 600,
                  maxWidth: 300,
                  minHeight: 354,
                  width: '100%',
                }}
                post={post}
              />
            </Col>
          ))}
        </Row>

        <div className='tw-flex tw-justify-center'>
          <ForwardLink
            className='tw-inline-block'
            href={blogUrl}
            target='_blank'
          >
            Visit the Blog
          </ForwardLink>
        </div>
      </Container>
    </Section>
  )
}
