import config from '@blissbook/ui-config'
import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import { Col, Container, ForwardLink, Link, Row } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import tinycolor from 'tinycolor2'
import {
  HeadingDivider,
  MarketingLayout,
  MaxWidthContainer,
  Section,
  Typography,
} from '../components'
import { CallToActionSection, SolutionSection } from './sections'

import { AnimatedExperience } from './Product/experiences/components'
import Personalized from './Product/experiences/personalized'

const backgroundColor = tinycolor(colors['purple-100'])
  .setAlpha(0.5)
  .toRgbString()
const color = 'purple-500'
const desktopHeight = 682
const transformBrowser =
  'perspective(2400px) rotateY(-25deg) rotateX(4deg) rotate(1deg)'

const featureIcon = (type) => (
  <FontAwesomeIcon
    icon={type}
    className='tw-mr-4 tw-mb-10'
    color={colors[color]}
    style={{
      fontSize: 80,
    }}
  />
)

const FEATURES = [
  {
    image: '/images/marketing/solutions/KeyCloudPurple.svg',
    title: 'Easy sign-in for employees.',
    description:
      'Blissbook supports a variety of no-password sign in methods (SSO, email, or phone number), so your content will be easy to access yet secure no matter how low-tech your workforce is.',
  },
  {
    icon: featureIcon(['fal', 'robot']),
    title: 'Secure, automated on- and off-boarding.',
    description:
      'Integrate Blissbook with your HRIS (Workday, UltiPro, etc.) and Single Sign-On providers to handle new employees – they’ll automatically see the policies or handbooks that are relevant to them.',
  },
  {
    icon: featureIcon(['fal', 'file-contract']),
    title: 'Robust electronic signature reporting.',
    description:
      'Different combos of personalized content can mean hundreds of variants of your policies. No worries! Blissbook tracks the exact content someone viewed when they signed their acknowledgment form.',
  },
  {
    icon: featureIcon(['fal', 'file-signature']),
    title: 'Custom signature rounds.',
    description:
      'Major changes to your California-only policies? That’s easy. Start a new signature round for only those employees who need to sign off on the new content.',
  },
]

export default () => (
  <MarketingLayout
    title='Personalized, customized policies and handbooks.'
    description={
      'Personalized digital handbooks from Blissbook. Customize what content employees can see and manage it all in one place.'
    }
  >
    <SolutionSection
      color={color}
      image='Target.svg'
      imageDesktopCss={css(`
        top: 90px;
        right: -90px;
        height: 363px;

        @media (min-width: ${gridBreakpoints.xl}px) {
          right: -60px;
        }
      `)}
      imageMobileCss={css(`
        transform: scaleX(-1);
      `)}
      greeting='Hyper-Personalized Content'
      title='Personalized policies and handbooks.'
      description='Handle multi-state handbooks with aplomb. With Blissbook, employees don’t have to figure out what content applies to them; it just works.'
    />

    <Section>
      <Container
        className='py-sm'
        style={{ height: desktopHeight, position: 'relative' }}
      >
        <Row
          className='tw-align-stretch tw-flex-row-reverse'
          style={{ height: '100%' }}
        >
          <Col
            lg={6}
            xl={6}
            className='tw-hidden lg:tw-block'
            style={{
              transform: config.env.development ? undefined : transformBrowser,
            }}
          >
            <AnimatedExperience
              style={{ height: desktopHeight }}
              experience={Personalized}
              in={true}
            />
          </Col>
          <Col lg={6} xl={5} className='lg:tw-pr-14 tw-my-auto'>
            <HeadingDivider variant='h2' divider={{ color }}>
              Hyper-Personalized Content
            </HeadingDivider>
            <Typography variant='p2'>
              Have content that only applies to a specific group of employees
              (ahem, California)? Although you can create a separate document in
              Blissbook, you don’t have to.
            </Typography>
            <Typography variant='p2'>
              Access control within a single handbook or document makes it easy
              for employees to find, view, and sign off on only the content
              that’s relevant to them.
            </Typography>
          </Col>
        </Row>
      </Container>
    </Section>

    <Section
      css={{
        background: `linear-gradient(180deg, white 0%, ${backgroundColor} 100%);`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row>
            {FEATURES.map((feature, index) => (
              <Col key={index} xs={12} md={6} className='tw-mb-10'>
                <MaxWidthContainer md={400}>
                  {feature.icon && feature.icon}
                  {feature.image && (
                    <img
                      alt={feature.image}
                      src={feature.image}
                      style={{ fill: colors['blurple-500'] }}
                      className='tw-mb-10'
                      css={{ height: 80 }}
                    />
                  )}
                  <Typography variant='h2'>{feature.title}</Typography>
                  <Typography variant='p2'>{feature.description}</Typography>
                </MaxWidthContainer>
              </Col>
            ))}
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Ready for personalized employee handbooks?'
      description='We’re here for you! Get in touch so we can learn about you and your project. We can give a demo and set up a free trial as needed.'
      contentClassName=''
      includeLogos
    >
      <div className='md:tw-flex tw-items-center tw-mb-8'>
        <Link className='btn btn-primary tw-mr-6' to='/pricing'>
          Try it free
        </Link>
        <ForwardLink className='tw-block tw-my-6' to='/request-a-demo'>
          Request a demo
        </ForwardLink>
      </div>
    </CallToActionSection>
  </MarketingLayout>
)
