import { gridBreakpoints } from '@blissbook/ui/branding'
import { BackLink, Button, Container } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { css } from '@emotion/react'
import classnames from 'classnames'
import React from 'react'

export const AuthBackLink = (props) => (
  <BackLink
    {...props}
    css={css`
      @media (min-width: ${gridBreakpoints.md}px) {
        position: absolute;
        top: 15px;
        left: 15px;
        margin-top: 0 !important;
      }
    `}
    to='/'
  >
    Back to Blissbook
  </BackLink>
)

export const AuthButton = (props) => {
  const { organization } = useStore()
  return (
    <Button
      color={organization ? 'dark' : 'primary'}
      {...props}
      css={css`
        min-width: 200px;
        padding: 0.5rem 1.5rem;
      `}
    />
  )
}

const ImageColumn = ({ className, imageUrl }) => (
  <div
    css={{ width: 194 }}
    className={classnames('tw-hidden tw-py-6', className)}
  >
    {/* biome-ignore lint/a11y/useAltText: if you see this line, try to fix it */}
    {imageUrl && <img src={imageUrl} width='100%' />}
  </div>
)

const FormColumn = ({ children, width }) => (
  <div
    css={css`
      background: rgba(255, 255, 255, 0.9);

      width: 100%;
      @media (min-width: ${gridBreakpoints.md}px) {
        width: auto;
      }
    `}
    className='tw-p-6'
  >
    <div
      css={{
        width: 'auto',
        [`@media (min-width: ${gridBreakpoints.md}px)`]: { width },
      }}
    >
      {children}
    </div>
  </div>
)

export const AuthLayout = ({ children, formWidth = 560, imageUrl }) => (
  <Container
    fluid
    className='tw-flex tw-items-start tw-justify-center tw-p-6'
    style={{ minHeight: 'min-content' }}
  >
    {imageUrl && (
      <ImageColumn className='lg:tw-block tw-mr-2' imageUrl={imageUrl} />
    )}
    <FormColumn width={formWidth}>{children}</FormColumn>
    {imageUrl && <ImageColumn className='xl:tw-block tw-ml-2' />}
  </Container>
)
