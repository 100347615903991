import { colors } from '@blissbook/ui/branding'
import {
  BackgroundImage,
  DesktopBrowser,
  FadeOutInTransition,
  Link,
  Shape,
  ShapeGrid,
} from '@blissbook/ui/lib'
import {
  useParallax,
  useParallaxScreenRatio,
} from '@blissbook/ui/util/parallax'
import { css } from '@emotion/react'
import range from 'lodash/range'
import React, { useEffect, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import tinycolor from 'tinycolor2'
import {
  MaxWidthContainer,
  Section,
  TESTIMONIALS,
  Typography,
} from '../../components'

const logoHeight = 50
const transitionDuration = 400
const transitionDelayMs = 6000

// Helper to get the testimonial at this index
const mod = (n, m) => ((n % m) + m) % m
const getTestimonial = (index) => {
  index = mod(index, TESTIMONIALS.length)
  return TESTIMONIALS[index]
}

export const TrustedByLogoGrid = () => {
  const logoCount = 10

  return (
    <MaxWidthContainer className='tw-mx-auto tw-mb-7'>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 2rem;
          justify-items: center;
          align-items: center;
        `}
      >
        {range(logoCount).map((index) => {
          const testimonial = getTestimonial(index)
          return (
            <Logo
              key={testimonial.id}
              src={testimonial.logoUrl}
              disableGrayscale={true}
              maxWidth='100px'
            />
          )
        })}
      </div>
    </MaxWidthContainer>
  )
}

const Logo = ({ isActive, src, disableGrayscale, maxWidth = '120px' }) => (
  <div
    css={css`
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      &:after {
        content: '';
        position: absolute;
        left: 5px;
        right: 5px;
        bottom: -10px;
        border-top: 3px solid ${
          isActive && !disableGrayscale ? colors['sunshine-500'] : 'transparent'
        };
        transition: all linear ${transitionDuration}ms;
      }
    `}
  >
    <img
      alt={src}
      css={css`
        filter: ${disableGrayscale || isActive ? 'none' : 'grayscale(100%)'};
        max-height: ${logoHeight}px;
        max-width: ${maxWidth};
        opacity: ${disableGrayscale || isActive ? 1 : 0.5};
        transition: all linear ${transitionDuration}ms;
        &:hover {
          filter: none;
        }
      `}
      src={src}
    />
  </div>
)

const Logos = ({
  currentIndex,
  setCurrentIndex,
  disableGrayscale,
  ...props
}) => {
  const logoCount = 5
  const cellWidth = 100 / logoCount
  const startIndex = currentIndex - logoCount
  const endIndex = currentIndex + logoCount * 2
  return (
    <div {...props} css={{ overflow: 'hidden' }}>
      <div
        css={{
          borderBottom: '10px solid transparent',
          height: logoHeight + 10,
          transition: `transform linear ${transitionDuration}ms`,
        }}
        style={{
          transform: `translateX(${-(currentIndex - 1) * cellWidth}%)`,
        }}
      >
        {range(startIndex, endIndex).map((index) => {
          const testimonial = getTestimonial(index)
          return (
            <div
              key={index}
              className='tw-flex tw-items-center tw-justify-center'
              css={{
                position: 'absolute',
                top: 0,
                height: '100%',
                cursor: 'pointer',
                width: '20%',
              }}
              style={{
                left: `${index * cellWidth}%`,
              }}
              onClick={() => setCurrentIndex(index)}
              onKeyUp={(e) => e.key === 'Enter' && setCurrentIndex(index)}
            >
              <Logo
                src={testimonial.logoUrl}
                isActive={index === currentIndex}
                disableGrayscale={disableGrayscale}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const HandbookImage = ({ scrollY, style, ...props }) => {
  const [node, setNode] = useState()
  const height = useParallax(node, (node) => node.offsetHeight)
  const parentHeight = useParallax(node, (node) => node.parentNode.offsetHeight)

  // Bound the top
  if (height && parentHeight) {
    const maxScrollY = height - parentHeight
    scrollY = Math.min(scrollY, maxScrollY)
  }

  return (
    // biome-ignore lint/a11y/useAltText: if you see this line, try to fix it
    <img
      {...props}
      css={{
        transition: 'top 1ms linear',
      }}
      ref={setNode}
      style={{ ...style, top: -scrollY }}
    />
  )
}

const Profile = ({ testimonial, ...props }) => (
  <MaxWidthContainer {...props} className='tw-flex' xs='75%' lg='none'>
    <div className='tw-mr-4'>
      <BackgroundImage
        src={testimonial.photoUrl}
        height={50}
        width={50}
        className='rounded-circle'
      />
    </div>
    <div>
      <Typography variant='h4' className='tw-mb-0 tw-text-gray-800'>
        {testimonial.name}
      </Typography>
      <Typography variant='p2' className='tw-mb-0'>
        <span className='tw-inline lg:tw-hidden'>{testimonial.title}</span>
        <span className='tw-hidden lg:tw-inline'>
          {testimonial.title} at{' '}
          <Link
            href={testimonial.companyUrl}
            css={{ color: 'inherit', '&:hover': { color: 'inherit' } }}
          >
            {testimonial.company}
          </Link>
        </span>
      </Typography>
    </div>
  </MaxWidthContainer>
)

const DesktopTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [inViewport, setInViewport] = useState(false)
  const [isAutomated, setAutomated] = useState(true)
  const [browserNode, setBrowserNode] = useState()
  const browserRatio = useParallaxScreenRatio(browserNode, {
    attachment: 'top',
    range: [0.5, -1],
  })
  const currentTestimonial = getTestimonial(currentIndex)

  // Automated scroll
  useEffect(() => {
    if (!isAutomated) return

    const timerId = setTimeout(
      () => setCurrentIndex(currentIndex + 1),
      transitionDelayMs,
    )
    return () => clearTimeout(timerId)
  }, [currentIndex, isAutomated])

  return (
    <Waypoint
      onEnter={({ waypointTop, waypointBottom }) => {
        // Don't fire if mobile
        if (waypointTop && waypointBottom) setInViewport(true)
      }}
    >
      <div className='tw-hidden lg:tw-block' style={{ position: 'relative' }}>
        <ShapeGrid
          css={{
            position: 'absolute',
            left: 0,
            bottom: -180,
          }}
          alignX='left'
          alignY='bottom'
          shapes={[
            {
              x: 1,
              y: 1,
              type: 'wedge',
              color: tinycolor(currentTestimonial.color)
                .setAlpha(0.2)
                .toRgbString(),
              fill: true,
              rotate: -90,
            },
            {
              x: 0,
              y: 0,
              type: 'square',
              color: tinycolor(currentTestimonial.color)
                .setAlpha(0.8)
                .toRgbString(),
              fill: true,
            },
          ]}
        />

        <Logos
          className='tw-mt-6 tw-mb-14'
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          onMouseEnter={() => setAutomated(false)}
          onMouseLeave={() => setAutomated(true)}
        />

        <div className='tw-flex tw-align-stretch'>
          <div css={{ flex: 1 }} className='tw-flex tw-justify-end tw-mr-18'>
            <DesktopBrowser
              className='box-shadow'
              css={{ width: '75%' }}
              height={700}
              onMouseEnter={() => setAutomated(false)}
              onMouseLeave={() => setAutomated(true)}
              ref={setBrowserNode}
            >
              {inViewport &&
                TESTIMONIALS.map((testimonial) => (
                  <FadeOutInTransition
                    key={testimonial.id}
                    fadeInDelay={transitionDuration}
                    fadeInDuration={transitionDuration}
                    fadeOutDuration={transitionDuration}
                    in={testimonial === currentTestimonial}
                  >
                    <HandbookImage
                      css={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                    `}
                      scrollY={!browserRatio ? 0 : browserRatio * 1250}
                      src={testimonial.handbookImageUrl}
                      width='100%'
                    />
                  </FadeOutInTransition>
                ))}
            </DesktopBrowser>
          </div>
          <div css={{ flex: 1, position: 'relative' }}>
            {inViewport &&
              TESTIMONIALS.map((testimonial) => (
                <FadeOutInTransition
                  key={testimonial.id}
                  fadeInDelay={transitionDuration}
                  fadeInDuration={transitionDuration}
                  fadeOutDuration={transitionDuration}
                  in={testimonial === currentTestimonial}
                >
                  <div
                    className='mt-sm'
                    css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  `}
                  >
                    <Shape
                      type='quotes'
                      color='sunshine-500'
                      size={50}
                      className='tw-mb-8'
                    />

                    <Typography variant='quote' className='tw-mb-10'>
                      {testimonial.quote}
                    </Typography>

                    <Profile testimonial={testimonial} />
                  </div>
                </FadeOutInTransition>
              ))}
          </div>
        </div>
      </div>
    </Waypoint>
  )
}

const MobileTestimonials = () => (
  <div className='lg:tw-hidden'>
    {TESTIMONIALS.filter((t) => t.isMobile).map((testimonial) => (
      <div
        className='tw-flex tw-flex-col tw-items-center tw-mt-14'
        key={testimonial.id}
      >
        <img
          alt={`Logo of ${testimonial.logoUrl}`}
          src={testimonial.logoUrl}
          className='tw-mb-6'
          css={{ maxHeight: 60, maxWidth: 200 }}
        />
        <Typography variant='quote' className='text-center tw-mb-6'>
          “{testimonial.quote}”
        </Typography>
        <Profile testimonial={testimonial} />
      </div>
    ))}
  </div>
)

const MobileLogoGrid = () => (
  <div className='tw-grid tw-grid-cols-2 tw-gap-y-12 lg:tw-hidden'>
    {TESTIMONIALS.map((testimonial) => (
      <div
        key={testimonial.id}
        className='tw-flex tw-justify-center tw-items-center'
      >
        <img
          alt={`Logo of ${testimonial.company}`}
          src={testimonial.logoUrl}
          css={{ maxHeight: 50, maxWidth: 120 }}
        />
      </div>
    ))}
  </div>
)

export default ({
  showTestimonials = true,
  title = `You're in Good Company`,
  description = 'Thousands of companies trust Blissbook with their policy management.',
  padding = 'py-sm',
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isAutomated, setAutomated] = useState(true)

  useEffect(() => {
    if (!isAutomated) return

    const timerId = setTimeout(
      () => setCurrentIndex(currentIndex + 1),
      transitionDelayMs,
    )
    return () => clearTimeout(timerId)
  }, [currentIndex, isAutomated])

  return (
    <Section id='testimonials' className={`container ${padding}`}>
      <MaxWidthContainer className='tw-mx-auto text-center mb-sm' xs={780}>
        <Typography variant='h3' className='lg:tw-mb-10'>
          {title}
        </Typography>
        <Typography variant='headline'>{description}</Typography>
      </MaxWidthContainer>
      {showTestimonials ? (
        <>
          <DesktopTestimonials />
          <MobileTestimonials />
        </>
      ) : (
        <div className='tw-mt-6 tw-mb-[110px]'>
          <Logos
            className='tw-hidden lg:tw-block'
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            onMouseEnter={() => setAutomated(false)}
            onMouseLeave={() => setAutomated(true)}
            disableGrayscale={!showTestimonials}
          />
          <MobileLogoGrid />
        </div>
      )}
    </Section>
  )
}
