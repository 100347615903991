import { colors, getColor, gridBreakpoints } from '@blissbook/ui/branding'
import { BackgroundImage, ForwardLink, Shape } from '@blissbook/ui/lib'
import {
  getScreenDelta,
  getScreenDeltaRatio,
  getScreenRatio,
  useParallax,
  useParallaxScreenRatio,
} from '@blissbook/ui/util/parallax'
import { css } from '@emotion/react'
import classnames from 'classnames'
import flatMap from 'lodash/flatMap'
import isNumber from 'lodash/isNumber'
import React, { Fragment, useState } from 'react'
import tinycolor from 'tinycolor2'
import { DotsHorizontal, Section, Typography } from '../../components'

const getParallaxBounceValue = (delta, minValue, maxValue) => {
  const range = maxValue - minValue
  const modulus = range * 2
  const tick = Math.abs(delta % modulus)
  return (tick > range ? modulus - tick : tick) + minValue
}

const ITEMS = [
  {
    to: '/employee-handbook-software#employee-handbook-builder',
    title: 'Create.',
    description:
      'Write, design, and assemble your handbook using our built-in, mobile-friendly template, or have our team write & create it for you.',
    icon: {
      src: '/images/marketing/home/Pen.svg',
      getParallaxValue: getScreenDelta,
      getParallaxStyle: (delta) => {
        const degrees = getParallaxBounceValue(delta / 4, 0, 10)
        return {
          transform: `rotate(${-degrees}deg)`,
          transformOrigin: '50% 100%',
        }
      },
    },
    image: (
      <div className='tw-flex tw-flex-col tw-items-end'>
        <img
          alt='Create'
          src='/images/marketing/home/Create-desktop.svg'
          className='box-shadow'
          style={{ marginRight: 40 }}
        />
        <img
          alt='Create'
          src='/images/marketing/home/Create-mobile.svg'
          className='box-shadow'
          style={{ marginTop: -100 }}
        />
      </div>
    ),
    imageOffsetY: -80,
    line: {
      lg: [110, 100],
      xl: [110, 100],
    },
    shape: { color: 'sunshine-500', colorWhite: 'sunshine-100', rotate: 90 },
  },
  {
    to: '/employee-handbook-software#online-employee-handbook',
    title: 'Launch.',
    description:
      'Built-in project management helps you get a handle on all the tasks that need to get done to launch a digital handbook to your audience.',
    icon: {
      src: '/images/marketing/home/Balloon.svg',
      getParallaxValue: (node) =>
        getScreenRatio(node, {
          range: [1, 0],
          styleKeys: ['transform'],
        }),
      getParallaxStyle: (ratio) => {
        const windowHeight = global.innerHeight || 0
        const delta = windowHeight * ratio
        const inRatio = Math.max(ratio - 0.25, 0)
        const inPx = inRatio * windowHeight
        const degrees = getParallaxBounceValue(delta / 6, -5, 5)

        return {
          transform: `translate(${-inRatio * 50}px, ${
            -inPx * 0.5
          }px) rotate(${degrees}deg)`,
          transformOrigin: '50% 0%',
        }
      },
    },
    image: (
      <div className='tw-flex tw-flex-col tw-items-end'>
        <img
          alt='Launch'
          src='/images/marketing/home/Launch-shapes.svg'
          className='box-shadow'
          style={{ marginRight: 40 }}
        />
        <img
          alt='publish'
          src='/images/marketing/home/Launch-publish.svg'
          style={{ marginTop: -40 }}
        />
      </div>
    ),
    imageOffsetY: -80,
    line: {
      lg: [880, 509],
      xl: [1085, 509],
    },
    shape: { color: 'red-500', colorWhite: 'red-50', rotate: -90 },
  },
  {
    to: '/employee-handbook-software#policy-management',
    title: 'Update.',
    description:
      'Easily make changes any time and communicate them with the right people so everyone stays in-the-know about your policies.',
    icon: {
      src: '/images/marketing/home/Arrows.svg',
      getParallaxValue: getScreenDeltaRatio,
      getParallaxStyle: (ratio) => ({
        transform: `rotate(${-ratio * 180}deg)`,
      }),
    },
    image: (
      <img
        alt='update'
        src='/images/marketing/home/Update.svg'
        className='box-shadow'
        style={{ maxWidth: '100%' }}
      />
    ),
    imageOffsetY: -80,
    line: {
      lg: [200, 930],
      xl: [225, 930],
    },
    shape: { color: 'aqua-500', colorWhite: 'aqua-100', rotate: 180 },
  },
  {
    to: '/employee-handbook-software#policy-compliance-tracking',
    title: (
      <Fragment>
        Track
        <small css={{ display: 'block', fontSize: 24 }}>(for compliance)</small>
      </Fragment>
    ),
    description:
      'Request, track, and report on legally binding electronic signatures and automate the whole process by integrating with your HRIS or Active Directory.',
    icon: {
      src: '/images/marketing/home/Binoculars.svg',
      getParallaxValue: getScreenDeltaRatio,
      getParallaxStyle: (ratio) => ({
        transform: `translateX(${-Math.abs(ratio) * 300}px)`,
      }),
    },
    image: (
      <div className='tw-flex tw-flex-col tw-items-end'>
        <img
          alt='track changes'
          src='/images/marketing/home/Track-box.svg'
          className='box-shadow'
          style={{ marginRight: 40, zIndex: 10 }}
        />
        <img
          alt='track changes'
          src='/images/marketing/home/Track-check.svg'
          style={{ marginTop: -40, zIndex: 10 }}
        />
      </div>
    ),
    imageOffsetY: -120,
    line: {
      lg: [870, 1347],
      xl: [1070, 1347],
    },
    shape: { color: 'blurple-500', colorWhite: 'blurple-200' },
  },
]

const shapeSize = 350

const ProductImage = ({ className, edge, image, shape, ...props }) => {
  const [node, setNode] = useState()
  const ratio = useParallaxScreenRatio(node, {
    attachment: 'center',
    range: [1, 0.5],
  })

  const shapeColor = tinycolor.mix(
    getColor(shape.colorWhite),
    getColor(shape.color),
    isNumber(ratio) ? ratio * 100 : 100,
  )

  return (
    <div
      {...props}
      className={classnames(className, 'parallax')}
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-${edge}: -15px;
        height: ${shapeSize}px;
        padding-bottom: 15px;
        padding-${edge}: 45px;
      `}
      ref={setNode}
    >
      <Shape
        {...shape}
        css={css`
          position: absolute;
          top: 0;
          ${edge}: 0;
          z-index: -1;
        `}
        color={shapeColor}
        fill
        size={shapeSize}
        type='wedge'
      />

      {image}
    </div>
  )
}

const iconMargin = 20
const ProductIcon = ({ className, icon, ...props }) => {
  const [node, setNode] = useState()
  const value = useParallax(node, icon.getParallaxValue)
  const style = isNumber(value) && icon.getParallaxStyle(value)

  return (
    <BackgroundImage
      {...props}
      className={classnames(className, 'parallax')}
      height={80}
      ref={setNode}
      size='contain'
      src={icon.src}
      style={style}
      width={60}
    />
  )
}

const ProductItem = ({
  description,
  icon,
  image,
  imageOffsetY = 0,
  index,
  shape,
  title,
  to,
}) => (
  <div
    css={css`
      position: relative;
      display: flex;
      align-items: center;
      @media (max-width: ${gridBreakpoints.lg - 1}px) {
        flex-direction: column;
      }
      @media (min-width: ${gridBreakpoints.lg}px) {
        flex-direction: ${index % 2 === 0 ? 'row-reverse' : 'row'};
        justify-content: space-between;
        padding-top: 60px;
        padding-bottom: 60px;
      }
    `}
  >
    <ProductImage
      css={css`
        @media (max-width: ${gridBreakpoints.lg - 1}px) {
          margin-bottom: 45px;
        }
        @media (min-width: ${gridBreakpoints.lg}px) {
          max-width: 45%;
          margin-top: ${imageOffsetY}px;
        }
      `}
      edge={index % 2 === 0 ? 'right' : 'left'}
      image={image}
      shape={shape}
    />

    <div
      css={css`
        display: flex;

        @media (min-width: ${gridBreakpoints.lg}px) {
          width: 50%;
        }
      `}
    >
      <div css={{ marginRight: iconMargin }}>
        <ProductIcon className='tw-bg-white' icon={icon} />
      </div>

      <div style={{ flex: 1, maxWidth: 380 }}>
        <Typography
          variant='h1'
          children={title}
          className='tw-inline-block tw-bg-white'
          css={{ marginLeft: -iconMargin, paddingLeft: iconMargin }}
        />
        <Typography variant='p1'>{description}</Typography>
        <ForwardLink to={to}>Learn More</ForwardLink>
      </div>
    </div>
  </div>
)

const classNameBySize = {
  lg: 'tw-hidden lg:tw-block xl:tw-hidden',
  xl: 'tw-hidden xl:tw-block',
}

const DottedLine = ({ size, ...props }) => {
  // Determine the x boundaries
  const points = ITEMS.map((item) => item.line[size])

  // Constants
  const strokeWidth = 5
  const radius = 50

  // Calculate the path
  const path = flatMap(points, (point, index) => {
    const commands = []
    const isEven = index % 2 === 0
    const [startX, yOffset] = point

    // Add the start
    if (index === 0) {
      commands.push(`M ${startX},${yOffset}`)
    } else {
      const arcDelta = isEven ? radius : -radius
      commands.push(`L ${startX},${yOffset - radius}`)
      commands.push(
        `A ${radius} ${radius} 0 0 ${isEven ? 0 : 1} ${
          startX + arcDelta
        },${yOffset}`,
      )
    }

    // Add the end point
    const [endX] = points[index + 1] || points[0]
    commands.push(`L ${endX},${yOffset}`)

    return commands
  }).join(' ')

  // Determine the class

  return (
    <svg
      {...props}
      css={{
        position: 'absolute',
        top: 0,
        height: 1724,
        left: 0,
        width: '100%',
      }}
      className={classNameBySize[size]}
      role='img'
      aria-label='Dotted line'
    >
      <path
        d={path}
        fill='none'
        stroke={colors['blurple-500']}
        strokeDasharray='0 20 0 20'
        strokeLinecap='round'
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default () => (
  <Section id='product' className='container tw-py-10 lg:tw-py-0'>
    <DottedLine size='lg' viewBox='0 0 972 1724' />
    <DottedLine size='xl' viewBox='0 0 1180 1724' />

    {ITEMS.map((item, index) => (
      <Fragment key={index}>
        {index > 0 && (
          <DotsHorizontal
            className='lg:tw-hidden tw-mx-auto tw-my-8'
            dotCount={15}
          />
        )}
        <ProductItem {...item} index={index} />
      </Fragment>
    ))}
  </Section>
)
